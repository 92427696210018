export const environment = {
  production: true,
  appVersion: '0.0.1',
  host: 'https://support.makktina.com',
  apiVersion: '/api/v1',
  apiUrl: 'https://support.makktina.com/api/v1',
  siteUrl: 'https://new.makktina.com',
  secretKey: 'A7H04OTimuhzSH2u9BLeeX2b5aLQMuLo',
  webPlatform: '5A663988-47DC-47B3-94FE-0B47F5F824A8',
  socketUrl: 'https://support.makktina.com/ws/chatHub'
};
